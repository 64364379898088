import _ from 'lodash'

import { flashSuccessMessage } from './bits/flashSuccessMessage'

export const addSuccessHandler = (servicesDeclarations) => servicesDeclarations.map(
  (serviceDeclaration) => ({
    ...serviceDeclaration,
    onReceivesData: (...all) => {
      const response = serviceDeclaration.onReceivesData && serviceDeclaration.onReceivesData(...all)

      if (response !== false) {
        successHandler(serviceDeclaration)(...all)
      }
    },
  })
)

export const successHandler = ({ name: serviceName }) => ({ name: actionName, method }, dispatch, getState) => {
  const dispatchMessage = (message) => dispatch(flashSuccessMessage(message))

  const state = getState()
  const data = _.get(state, `${serviceName}.${actionName}.data`)

  switch (method) {
    case 'DELETE':
      dispatchMessage((data && data._archived) ? 'Zarchiwizowano!' : 'Przywrócono!')
      return
    case 'POST':
      dispatchMessage('Utworzono nowy rekord!')
      return
    case 'PUT':
      dispatchMessage('Zapisano zmiany!')
      return
    default:
      return
  }

}

export default successHandler