import uuidv1 from 'uuid/v1'

const makeQuestionBaseFromTemplate = (owner) => (questionTemplate) => ({
  type: questionTemplate.type,
  caption: questionTemplate.caption,
  description: questionTemplate.description,
  questionId: uuidv1(),
  owner: owner || null,
})

export const generatePoll = ({ getTemplate, getCourse, createPoll }) => (
  async ({ template, course, trainers, ...restOfData }) => {
    const templateData = await getTemplate({ id: template })
    const courseData = await getCourse({ id: course })
    const support = courseData.support

    const templateQuestions = templateData && templateData.questions

    const questionsPerTrainers = (
      trainers &&
      trainers.flatMap(({ trainer, subject }) => {
        return templateQuestions
          .filter((questionTemplate) => questionTemplate.repeat === 'per-trainer')
          .map((questionTemplate) => ({
            ...makeQuestionBaseFromTemplate(trainer)(questionTemplate),
            trainer,
            subject,
          }))
      })
    ) || []

    const questions = questionsPerTrainers.concat(
      (
        templateQuestions &&
        templateQuestions
          .filter((question) => question.repeat === 'per-poll')
          .map(makeQuestionBaseFromTemplate(support))
      ) || []
    )

    return createPoll({}, {
      data: {
        ...restOfData,
        course,
        questions,
        template,
      },
    })
  }
)

export default generatePoll