import React from 'react'
import PropTypes from 'prop-types'

import { Fab } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const styles = {
  root: {
    zIndex: 100,
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
}

const AddFloatingButton = ({ fullWidth, ...otherProps }) => (
  <Fab
    style={styles.root}
    color={'primary'}
    {...otherProps}
  >
    <Add />
  </Fab>
)

AddFloatingButton.propTypes = {
  fullWidth: PropTypes.bool
}

export default AddFloatingButton