import React, { useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { useSelector } from 'react-redux'
import { useService } from '../../bits/redux-rest-services/useService'
import { useDataItemFromStore } from '../../bits/redux-rest-services/useDataItemFromStore'

import { useTranslation } from 'react-i18next'

import { Typography } from '@material-ui/core'

import Uniform from '../../bits/uniforms/Uniform'

import AddButtons from './AddButtons'
import makeSchema from './formSchema'
import generatePoll from './generatePoll'

const DialogBody = ({ closeDialog }) => {
  const { t } = useTranslation()

  const [model, setModel] = useState(undefined)

  const userUid = useSelector((state) => state.auth && state.auth.userData && state.auth.userData._id)

  const pollsData = useDataItemFromStore('collections', { query: { name: 'polls' } })
  const answersData = useDataItemFromStore('collections', { query: { name: 'answers' } })
  const coursesOptions = _.get(pollsData, 'schema.properties.course.options')
  const templatesOptions = _.get(pollsData, 'schema.properties.template.options')
  const trainersOptions = _.get(answersData, 'schema.properties.trainerUid.options')

  const { Loader: TemplateLoader, get: getTemplate } = useService('actions', { collectionName: 'templates' })
  const { Loader: CourseLoader, get: getCourse } = useService('actions', { collectionName: 'courses' })
  const { create: createPoll, find } = useService('actions', { collectionName: 'polls' })

  const populate = Object.keys((pollsData && pollsData.populateSchema) || {})
  const params = {}
  const reloadPolls = () => find({ populate, ...params })

  const savePoll = (data) => (
    generatePoll({ getTemplate, getCourse, createPoll })({ ...data, author: userUid })
      .then(() => reloadPolls())
      .then(() => setModel(undefined))
      .then(() => closeDialog())
  )

  const schema = (
    coursesOptions &&
    templatesOptions &&
    trainersOptions &&
    makeSchema(coursesOptions, templatesOptions, trainersOptions)
  )

  return (
    schema ?
      <>
        <Typography variant={'h5'}>
          {t('Add new poll from template')}
        </Typography>
        <br />
        <TemplateLoader>
          <CourseLoader>
            <Uniform
              model={model}
              onChangeModel={setModel}
              schema={schema}
              submitLabel={t('Submit')}
              onSubmit={savePoll}
            />
          </CourseLoader>
        </TemplateLoader>
        <AddButtons />
      </>
      :
      null
  )
}

DialogBody.propTypes = {
  closeDialog: PropTypes.func.isRequired,
}

export default DialogBody