import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'

import DialogWithButton from '../../bits/DialogWithButton'

import AddItemDialog from './AddItemDialog'

const AddButtons = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container={true}>
      <Grid item={true} sm={6}>
        <DialogWithButton
          label={'Nowy trener'}
          closeDialogProp={'closeDialog'}
          dialogProps={{
            closeLabel: t('Cancel'),
          }}
        >
          <AddItemDialog collectionName={'trainers'} />
        </DialogWithButton>
      </Grid>
      <Grid item={true} sm={6}>
        <DialogWithButton
          label={'Nowy kurs'}
          closeDialogProp={'closeDialog'}
          dialogProps={{
            closeLabel: t('Cancel'),
          }}
        >
          <AddItemDialog collectionName={'courses'} />
        </DialogWithButton>
      </Grid>
    </Grid>
  )
}

export default AddButtons