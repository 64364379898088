import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { Uniform } from '../../bits/uniforms/Uniform'
import { useDataItemFromStore } from '../../bits/redux-rest-services/useDataItemFromStore'
import { useService } from '../../bits/redux-rest-services/useService'

import CheckCollectionPermissions from '../../bits/CheckCollectionPermissions'

const AddItemDialog = ({ collectionName, closeDialog }) => {
  const { t } = useTranslation()

  const collectionData = useDataItemFromStore('collections', { query: { name: collectionName } })
  const { create, Loader: ActionsLoader } = useService('actions', { collectionName })
  const { find, Loader: CollectionsLoader } = useService('collections')

  const schema = collectionData && collectionData.schema

  const onSubmit = (data) => (
    create({}, { data })
      .then(() => find())
      .then(() => closeDialog && closeDialog())
  )

  return (
    <CheckCollectionPermissions
      collectionData={collectionData}
      checks={['create']}
    >
      <CollectionsLoader>
        <ActionsLoader>
          <Uniform
            schema={schema}
            onSubmit={onSubmit}
            submitLabel={t('Submit')}
          />
        </ActionsLoader>
      </CollectionsLoader>
    </CheckCollectionPermissions>
  )
}

AddItemDialog.propTypes = {
  closeDialog: PropTypes.func,
  collectionName: PropTypes.string.isRequired,
}

export default AddItemDialog