import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DialogWithButton from '../../bits/DialogWithButton'

import AddFloatingButton from './AddFloatingButton'
import DialogBody from './DialogBody'

const AddPollFromTemplateDialog = (props) => {
  const { t } = useTranslation()

  const pathname = useSelector((state) => state.router.location.pathname)
  const isOnCollectionsPollsPath = pathname === '/collections/polls'
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn)

  return (
    (isUserLoggedIn && isOnCollectionsPollsPath) ?
      <DialogWithButton
        buttonComponent={AddFloatingButton}
        closeDialogProp={'closeDialog'}
        dialogProps={{
          closeLabel: t('Cancel'),
        }}
      >
        <DialogBody closeDialog={() => { /* this prop closes dialog */ }} />
      </DialogWithButton>
      :
      null
  )
}

AddPollFromTemplateDialog.propTypes = {}

export default AddPollFromTemplateDialog