import _ from 'lodash'

const now = new Date()
now.setHours(0, 0, 0, 0)
const todayAtNoon = now.toISOString()

export const makeSchema = (coursesOptions, templatesOptions, trainersOptions) => {
  return {
    type: 'object',
    required: ['template', 'toDate', 'fromDate', 'number', 'course'],
    properties: {
      template: {
        type: 'string',
        default: _.get(templatesOptions, '0.value'),
        options: templatesOptions,
        uniforms: {
          label: 'Rodzaj ankiety',
          component: 'MuiReactSelectField',
          isClearable: false,
        },
      },
      fromDate: {
        type: 'string',
        default: todayAtNoon,
        format: 'date-time',
        errorMessage: 'Data rozpoczęcia musi być mniejsza lub równa dacie zakończenia!',
        formatMaximum: {
          $data: '1/toDate',
        },
        uniforms: {
          label: 'Data rozpoczęcia',
          component: 'MuiPickerField',
          type: 'date',
        },
      },
      toDate: {
        type: 'string',
        default: todayAtNoon,
        format: 'date-time',
        errorMessage: 'Data zakończenia musi być większa lub równa dacie rozpoczęcia!',
        formatMinimum: {
          $data: '1/fromDate',
        },
        uniforms: {
          label: 'Data zakończenia',
          component: 'MuiPickerField',
          type: 'date',
        },
      },
      number: {
        type: 'string',
        default: '1',
        options: Array(100).fill(1).map((el, i) => ({
          value: String(i + 1),
          label: String(i + 1),
        })),
        uniforms: {
          label: 'Numer ankiety',
          component: 'MuiReactSelectField',
          isClearable: false,
        },
      },
      course: {
        type: 'string',
        default: _.get(coursesOptions, '0.value'),
        options: coursesOptions,
        uniforms: {
          label: 'Kurs',
          component: 'MuiReactSelectField',
          isClearable: false,
        },
      },
      trainers: {
        type: 'array',
        uniforms: {
          label: 'Trenerzy',
          component: 'ListFieldReorder',
        },
        items: {
          type: 'object',
          required: ['trainer', 'subject'],
          errorMessage: '',
          properties: {
            trainer: {
              type: 'string',
              options: trainersOptions,
              default: _.get(trainersOptions, '0.value'),
              errorMessage: 'Musisz wybrać trenera!',
              uniforms: {
                label: 'Trener',
                component: 'MuiReactSelectField',
                isClearable: false,
              },
            },
            subject: {
              type: 'string',
              errorMessage: 'Musisz wpisać temat zajęć!',
              uniforms: {
                label: 'Temat zajęć',
              },
            },
          },
        },
      },
    },
  }
}

export default makeSchema