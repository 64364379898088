export const theme = {
  palette: {
    primary: {
      main: '#359b98',
    },
    secondary: {
      main: '#ae0193',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
}

export default theme